import { IPieChart } from "models/Interfaces/ICharts";
import { colours } from "../Colours";
import "./Style.css";

function PieChart({ config }: IPieChart) {
  const degrees = [];
  let counter = 0;

  for (let i = 0; i < config.responses.length; i++) {
    let colourConfig = "";
    const slice = (config.responses[i].percentage / 100) * 360 + counter;

    counter = slice;

    colourConfig = `${colours[i]} 0 ${slice}deg`;

    degrees.push(colourConfig);
  }

  const imgConfigString = `conic-gradient(${degrees.toString()})`;

  return (
    <div className="pieChartContainer">
      <div
        className="pieChart tooltip"
        style={{
          backgroundImage: imgConfigString,
        }}
      >
        <span className="hover-key">
          {config.responses.map((item: any, index: any) => {
            return (
              <div className="segmentationKeyLine">
                <div
                  className="segmentationKeyColour"
                  style={{ backgroundColor: colours[index] }}
                />
                <div className="segmentationKeyText">{`${item.responseOption} : ${item.participantsCount} (${item.percentage}%)`}</div>
              </div>
            );
          })}
        </span>
      </div>
    </div>
  );
}

export default PieChart;
