import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { ICompareBarChart } from "models/Interfaces/ICharts";
import "./Style.css";

function CompareBarChart({ responses, tab }: ICompareBarChart) {
  return (
    <div className="compareBarContainer">
      <div className="legend-lines">
        {[...Array(10)].map((_, i) => (
          <div key={i} className="legend-line" />
        ))}
      </div>
      {responses.map((item, index) => (
        <div key={index} className="compareBarItem">
          <div className="compareBarQuestionAndPercentages">
            <div className="compareBarQuestionText">{item.description}</div>
            <div className="compareBarQuestionPercentages">
              {item.responses.map((response: any, subIndex: number) => (
                <div
                  key={subIndex}
                  className={`compareBarItemPercentages ${
                    subIndex === 1 ? "opaqueBar" : ""
                  }`}
                >
                  {tab === ReportingTypeEnum.Result &&
                  response.noQuestionMessage &&
                  response.noQuestionMessage !== "" ? (
                    <p>{response.noQuestionMessage}</p>
                  ) : tab === ReportingTypeEnum.Priorities &&
                    response.noPriorityMessage &&
                    response.noPriorityMessage !== "" ? (
                    <p>{response.noPriorityMessage}</p>
                  ) : (
                    <>
                      {response.negativePercentage > 0 && (
                        <div
                          className={`negativeResponses responseSegment tooltip ${
                            subIndex === 1 ? "negative-opaque" : ""
                          }`}
                          style={{ width: `${response.negativePercentage}%` }}
                        >
                          <span className="tooltiptext">{`${response.negativePercentage}%`}</span>
                        </div>
                      )}
                      {response.neutralPercentage > 0 && (
                        <div
                          className={`neutralResponses responseSegment tooltip ${
                            subIndex === 1 ? "neutral-opaque" : ""
                          }`}
                          style={{ width: `${response.neutralPercentage}%` }}
                        >
                          <span className="tooltiptext">{`${response.neutralPercentage}%`}</span>
                        </div>
                      )}
                      {response.positivePercentage > 0 && (
                        <div
                          className={`positiveResponses responseSegment tooltip ${
                            subIndex === 1 ? "positive-opaque" : ""
                          }`}
                          style={{ width: `${response.positivePercentage}%` }}
                        >
                          <span className="tooltiptext">{`${response.positivePercentage}%`}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="legend-percentages">
        {[...Array(11)].map((_, i) => (
          <div key={i} className="legend-percentage">
            {i * 10}%
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompareBarChart;
