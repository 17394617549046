export const API_URLS = {
  // AUTH
  SignIn: "/api/Auth/SignIn",
  Login: "/api/Auth/LogIn",
  SelfRegisterOrganisation: "/api/Auth/SelfRegisterOrganisation",
  ForgotPassword: "/api/Auth/ForgotPassword",
  ResetPassword: "/api/Auth/ResetPassword",
  RefreshToken: "/api/Auth/refresh-token",
  VerifyTwoFactorCode: "/api/Auth/VerifyTwoFactorCode",
  Is2FAEnabled: "/api/Auth/Is2FAEnabled",
  EnableTwoFactor: "/api/Auth/EnableTwoFactor",
  DisableTwoFactor: "/api/Auth/DisableTwoFactor",
  // USERS
  GetAdminUserList: "/api/Users/GetAdminUsersList",
  GetUserList: "/api/Users/GetUsersList",
  GetClientUsers: "/api/OrganisationUsers/GetClientUsers",
  GetAllRegularUsers: "/api/Users/GetAllRegularUsers",
  GetAPICredentials: "/api/Users/GetAPICredentials",
  GetUsersDetailsById: "/api/Users/GetUsersDetailsByUserId",
  RegisterAndWiderAdmin: "/api/Users/RegisterAndWiderAdmin",
  ApproveUser: "/api/Users/ApproveUser",
  GetUserOrganisations: "/api/Users/GetUserOrganisations",
  RemoveOrganisationsUser: "/api/Users/RemoveUser",
  UpdateUserDetails: "/api/Users/UpdateUserDetails",
  UpdateEmail: "/api/Users/ChangeUsername",
  UpdatePassword: "/api/Users/ChangePassword",
  UpdateClientPassword: "/api/Users/UpdateClientPassword",
  GetClientRoles: "/api/Users/GetClientRoles",
  // SURVEY TEMPLATES
  GetSurveyTemplates: "/api/SurveyTemplate/GetSurveyTemplates",
  GetSurveyTemplateTypes: "/api/SurveyTemplate/GetSurveyTemplateTypes",
  GetQuestionsForSurveyTemplateSetUp:
    "/api/SurveyTemplate/GetQuestionsForSurveyTemplateSetUp",
  CreateSurveyTemplate: "/api/SurveyTemplate/CreateSurveyTemplate",
  CreateSurveyTemplateType: "/api/SurveyTemplate/CreateSurveyTemplateType",
  GetSurveyTemplate: "/api/SurveyTemplate/GetSurveyTemplateBySurveyTemplateId",
  UpdateSurveyTemplate: "/api/SurveyTemplate/UpdateSurveyTemplate",
  DuplicateSurveyTemplate: "/api/SurveyTemplate/DuplicateSurveyTemplate",
  DeleteSurveyTemplate: "/api/SurveyTemplate/DeleteSurveyTemplate",
  // SECTORS
  GetSectors: "/api/Sectors/GetSectors",
  CreateSector: "/api/Sectors/CreateSector",
  GetSubSectors: "/api/SubSectors/GetSubSectors",
  CreateSubSector: "/api/SubSectors/CreateSubSector",
  // ORGANISATIONS
  GetInternalSectors: "/api/Organisations/GetInternalSectors",
  GetTags: "/api/Organisations/GetTags",
  GetChannelPartners: "/api/Organisations/GetChannelPartners",
  CreateOrganisation: "/api/Organisations/CreateOrganisations",
  GetOrganisation: "/api/Organisations/GetOrganisationByOrganisationId",
  EditOrganisation: "/api/Organisations/UpdateOrganisations",
  GetOrganisations: "/api/Organisations/GetOrganisationsList",
  GetOrganisationsWithSites: "/api/Organisations/GetOrganisationsWithSites",
  GetOrganisationSites: "/api/Sites/GetOrganisationSites",
  GetSelectOrganisationOptions:
    "/api/Organisations/GetSelectOrganisationOptions",
  DeleteOrganisation: "/api/Organisations/DeleteOrganisations",
  GetIncompleteOrganizations: "/api/Organisations/GetIncompleteOrganizations",
  CreateTag: "/api/Organisations/CreateTag",
  DeleteTag: "/api/Organisations/DeleteTag",
  UpdateTag: "/api/Organisations/UpdateTag",
  //SITES
  GetSites: "/api/Sites/GetOrganisationSites",
  GetSite: "/api/Sites/GetOrganisationSitesBySiteId",
  CreateSite: "/api/Sites/CreateOrganisationSites",
  UpdateSite: "/api/Sites/UpdateOrganisationSites",
  DeleteSite: "/api/Sites/DeleteOrganisationSites",
  GetOrgSitesByOrgIdAndUserId: "/api/Sites/GetOrgSitesByOrgIdAndUserId",
  GetOrganisationSitesForReportsScreen:
    "api/Sites/GetOrganisationSitesByOrganisationId",
  GetOrganisationSitesByOrganisationIds:
    "api/Sites/GetOrganisationSitesByOrganisationIds",
  GetReportsForMap: "api/Sites/GetReportsForMap",
  GetSitesForMap: "api/Sites/GetSitesForMap",
  GetSitesForList: "api/Sites/GetSitesForList",
  // ORGANISATION USERS
  GetOrganisationUsersList: "/api/OrganisationUsers/GetOrganisationUsersList",
  GetOrganisationUser: "/api/OrganisationUsers/GetOrganisationUserByIds",
  InviteOrganisationUser: "/api/OrganisationUsers/InviteOrganisationUser",
  InviteUserToOrganisations: "/api/OrganisationUsers/InviteUserToOrganisations",
  UpdateOrganisationUser: "/api/OrganisationUsers/UpdateOrganisationUser",
  // PHONE NUMBERS
  UploadPhoneNumber: "/api/PhoneNumbers/UploadWorkersPhoneNumber",
  GetWorkersPhoneNumber: "/api/PhoneNumbers/GetWorkersPhoneNumber",
  DownloadWorkersPhoneNumber: "/api/PhoneNumbers/DownloadWorkersPhoneNumber",
  //QUESTIONS
  GetAudiosAndTranslations: "api/Questions/GetQuestionAudiosAndTranslations",
  AddQuestionAudiosAndTranslations:
    "/api/Questions/AddQuestionAudiosAndTranslations",
  DeleteAudiosAndTranslations: "/api/Questions/DeleteAudiosAndTranslations",
  GetIndicatorFamilies: "/api/Questions/GetIndicatorFamilies",
  GetIndicators: "/api/Questions/GetIndicators",
  GetAllIndicators: "/api/Questions/GetAllIndicators",
  GetQuestionsList: "/api/Questions/GetQuestionsList",
  GetQuestionByQuestionId: "/api/Questions/GetQuestionByQuestionId",
  ArchivedQuestions: "/api/Questions/ArchivedQuestions",
  CreateQuestions: "/api/Questions/CreateQuestions",
  UpdateQuestions: "/api/Questions/UpdateQuestions",
  GetQuestionTypes: "/api/Questions/GetQuestionTypes",
  //LANGUAGES
  GetLanguages: "/api/Questions/GetLanguages",
  GetLanguageById: "/api/Questions/GetLanguageById",
  DialectsLanguages: "/api/Questions/GetLanguageDialects",
  CreateLanguage: "/api/Questions/CreateLanguage",
  UpdateLanguage: "/api/Questions/UpdateLanguage",
  // DATA CYCLES
  DataCollectionMethod: "/api/DataCycle/GetDataCollectionMethods",
  GetDataCycles: "/api/DataCycle/GetAllDataCycles",
  GetFilteredDataCycles: "/api/DataCycle/GetSurveyCycles",
  CreateDataCycle: "/api/DataCycle/CreateDataCycle",
  DeleteDataCycle: "/api/DataCycle/DeleteDataCycle",
  EditDataCycle: "/api/DataCycle/UpdateDataCycle",
  GetDataCycleById: "/api/DataCycle/GetDataCycleById",
  GetDataCycleNotes: "/api/DataCycle/GetDataCycleNotesByDataCycleId",
  GetDataCycleNoteById: "/api/DataCycle/GetDataCycleNoteById",
  DeleteDataCycleNote: "/api/DataCycle/DeleteDataCycleNote",
  CreateDataCycleNote: "/api/DataCycle/CreateDataCycleNote",
  EditDataCycleNote: "/api/DataCycle/UpdateDataCycleNote",
  MarkDataCycleAsComplete: "/api/DataCycle/MarkDataCycleAsComplete",
  GetMissingTranslations: "/api/DataCycle/GetMissingTranslations",
  HideSegmentationCharts: "/api/DataCycle/HideSegmentationChart",
  NukeSurveyCycle: "/api/DataCycle/NukeSurveyCycle",
  GetDataCycleSeasons: "/api/DataCycle/GetAllDataCycleSeasons",
  ToggleFilters: "/api/DataCycle/ToggleFilters",
  ReadDataCyclesCSV: "/api/DataCycle/ReadDataCyclesCSV",
  CreateMultipleDataCycles: "/api/DataCycle/CreateMultipleDataCycles",
  BulkDataCyclesAsComplete: "/api/DataCycle/MarkBulkDataCyclesAsComplete",
  BulkHideSegmentationCharts: "/api/DataCycle/HideBulkSegmentationCharts",
  BulkDisableSurveyCycleFilters: "/api/DataCycle/BulkDisableSurveyCycleFilters",
  SendEmails: "/api/DataCycle/SendCompletedSurveysNotification",
  // REPORTS
  SurveyTemplates: "/api/Reports/GetSurveyTemplatesOfOrganisation",
  GetDataCyclesForReportScreen:
    "/api/Reports/GetOrgDataCyclesBySiteIdAndTypeId",
  GetSurveyTemplateTypesForReportScreen:
    "/api/SurveyTemplate/GetSurveyTemplateTypesForSite",
  GetSurveyTemplateTypesForOrganisation:
    "/api/SurveyTemplate/GetSurveyTemplateTypesForOrganisation",
  GetSegmentationQuestions:
    "/api/Questions/GetSegmentationQuestionsByDataCycleId",
  GetSegmentationQuestionsComparison:
    "/api/Questions/GetSegmentationQuestionsComparison",
  GetSiteScore: "/api/Reports/GetSiteScore",
  GetSiteScoreByDataCycleId: "/api/Reports/GetSiteScoreByDataCycleId",
  GetAllResults: "/api/Reports/GetDetailResults",
  GetSitePriorities: "/api/Reports/GetPriorities",
  GetResponseRates: "/api/Reports/GetResponseRates",
  GetSegmentationResults: "/api/Reports/GetSegmentationQuestionResponseRates",
  GetPrioritiesOverview: "/api/Reports/GetPrioritiesOverview",
  GetProgressOverview: "/api/Reports/GetProgressDirections",
  GetResultsComparison: "/api/Reports/GetDetailedResultsComparison",
  GetPrioritiesComparison: "/api/Reports/GetPrioritiesComparison",
  GetProgressChart: "/api/Reports/GetProgressResults",
  GetSegmentationComparison: "/api/Reports/CompareSegmentationChart",
  GetStatusBadges: "/api/Reports/GetStatusBadges",

  // RESOURCES
  GetAllResourcesList: "/api/Resource/GetAllResourcesList",
  GetAllVideoResourcesList: "/api/Resource/GetAllVideoResourcesList",
  GetAllPDFResourcesList: "/api/Resource/GetAllPDFResourcesList",
  GetResourceByResourceId: "api/Resource/GetResourceByResourceId",
  CreateResource: "api/Resource/CreateResource",
  UpdateResource: "/api/Resource/UpdateResource",
  DeleteResource: "api/Resource/DeleteResource",
  GetResourceTypes: "/api/Resource/GetResourceTypes",
  GetResourceGroups: "/api/Resource/GetResourceGroups",

  // DASHBOARDS
  SurveyCyclesForOrgDashboard: "/api/Dashboard/GetSurveyCyclesForOrgDashboard",

  // DOWNLOADS
  DownloadPriorities: "/api/Downloads/DownloadPrioritiesCSV",
  DownloadResults: "/api/Downloads/DownloadResultsCSV",
  DownloadSiteSummaries: "/api/Downloads/DownloadSiteSummariesCSV",
  DownloadSegmentationCharts: "/api/Downloads/DownloadSegmentationChartsCSV",
  DownloadResponseRates: "/api/Downloads/DownloadResponseRatesCSV",
};
