import { useEffect, useState } from "react";
import { colours } from "components/Charts/Colours";
import { SmallSpinner } from "components/Spinner";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

function SegmentationKey({ dataCycleId, selectedQuestion }: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion) {
      const url = API_URLS.GetSegmentationResults;

      API.post(url, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          demographics.segmentationQuestion = response?.data?.chartTitle;
          demographics.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographics(demographics);
          setDemographicsLoaded(true);
        })
        .finally(() => {});
    }
  }, [dataCycleId, selectedQuestion]);

  return (
    <div>
      {demographicsLoaded ? (
        <div className="segmentationKeyContainer">
          {demographics.responses.map((item: any, index: any) => {
            return (
              <div className="segmentationKeyLine">
                <div
                  className="segmentationKeyColour"
                  style={{ backgroundColor: colours[index] }}
                />
                <div className="segmentationKeyText">{`${item.responseOption}: ${item.participantsCount} (${item.percentage}%)`}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ width: "150px", height: "150px" }}>
          <SmallSpinner />
        </div>
      )}
    </div>
  );
}

export default SegmentationKey;
