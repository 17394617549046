import { IHorizontalBarChart } from "models/Interfaces/ICharts";
import "./Style.css";

function HorizontalBarChart({ items }: IHorizontalBarChart) {
  return (
    <div className="hosizontalBarContainer">
      <div className="legend-lines">
        {[...Array(10)].map((_, i) => (
          <div key={i} className="legend-line" />
        ))}
      </div>
      {items.map((item: any, index: number) => {
        return (
          <div key={index} className="hosizontalBarItem">
            <div className="hosizontalBarQuestionAndPercentages">
              <div className="hosizontalBarQuestion">{item.question}</div>
              <div className="hosizontalBarPercentages">
                {item.negativePercentage > 0 && (
                  <div
                    className="negativeResponses responseSegment tooltip"
                    style={{ width: `${item.negativePercentage}%` }}
                  >
                    <span className="tooltiptext">{`${item.negativePercentage}%`}</span>
                  </div>
                )}
                {item.neutralPercentage > 0 && (
                  <div
                    className="neutralResponses responseSegment tooltip"
                    style={{ width: `${item.neutralPercentage}%` }}
                  >
                    <span className="tooltiptext">{`${item.neutralPercentage}%`}</span>
                  </div>
                )}
                {item.positivePercentage > 0 && (
                  <div
                    className="positiveResponses responseSegment tooltip"
                    style={{ width: `${item.positivePercentage}%` }}
                  >
                    <span className="tooltiptext">{`${item.positivePercentage}%`}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div className="legend-percentages">
        {[...Array(11)].map((_, i) => (
          <div key={i} className="legend-percentage">
            {i * 10}%
          </div>
        ))}
      </div>
    </div>
  );
}

export default HorizontalBarChart;
