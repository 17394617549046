import { useEffect, useState } from "react";
import { IPieChart } from "models/Interfaces/ICharts";
import { colours } from "../Colours";
import "./Style.css";

function ComparePieChart({ config }: IPieChart) {
  const [firstConfig, setFirstConfig] = useState("");
  const [secondConfig, setSecondConfig] = useState("");

  useEffect(() => {
    if (config.length > 0) {
      config.map((item: any, index: any) => {
        if (item.codedResponses !== null) {
          const degrees = [];
          let counter = 0;

          for (let i = 0; i < item.codedResponses.length; i++) {
            let colourConfig = "";
            const slice =
              (item.codedResponses[i].participantsPercentage / 100) * 360 +
              counter;
            counter = slice;

            colourConfig = `${colours[i]} 0 ${slice}deg`;

            degrees.push(colourConfig);
          }

          const imgConfigString = `conic-gradient(${degrees.toString()})`;

          if (index === 0) {
            setFirstConfig(imgConfigString);
          } else {
            setSecondConfig(imgConfigString);
          }
          return true;
        }
        return null;
      });
    }
  }, [JSON.stringify(config)]);

  return (
    <div className="comparePieChartContainer">
      {config.length > 0 &&
        config.map((item: any, index: any) => {
          if (item.codedResponses !== null) {
            return (
              <div className="compareHeadingAndChart">
                <h2 className="questionText">{item.dataCycleName}</h2>
                <div
                  className="comparePieChart"
                  style={{
                    backgroundImage: `${
                      index === 0 ? firstConfig : secondConfig
                    }`,
                    opacity: `${index === 0 ? "1" : "0.6"}`,
                  }}
                >
                  <span className="hover-key">
                    {config[index].codedResponses.map((x: any, y: any) => {
                      return (
                        <div className="segmentationKeyLine">
                          <div
                            className="segmentationKeyColour"
                            style={{ backgroundColor: colours[y] }}
                          />
                          <div className="segmentationKeyText">{`${x.keypadDesription}: ${x.participantsCount} (${x.participantsPercentage}%)`}</div>
                        </div>
                      );
                    })}
                  </span>
                </div>
              </div>
            );
          }
          return (
            <div className="comparePieChartEmpty">
              <h2 className="questionText">{item.dataCycleName}</h2>
              <div className="comparePieChartEmptyText">
                <p>This question was not asked.</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ComparePieChart;
