import { ParticipantEnum } from "models/Enum/EnumTypes";
import ResponseRates from "./ResponseRates";
import Segmentation from "./Segmentation";
import SegmentationCompare from "./SegmentationCompare";
import "./Style.css";

function Participation({
  selectedQuestion,
  siteId,
  surveyTemplateTypeId,
  dataCycleId,
  compareDataCycleId,
}: any) {
  return selectedQuestion.responseChartType === ParticipantEnum.BarChart ? (
    <ResponseRates
      siteId={siteId}
      surveyTemplateTypeId={surveyTemplateTypeId}
    />
  ) : compareDataCycleId === "" ? (
    <Segmentation
      selectedQuestion={selectedQuestion}
      dataCycleId={dataCycleId}
    />
  ) : (
    <SegmentationCompare
      selectedQuestion={selectedQuestion}
      dataCycleId={dataCycleId}
      compareDataCycleId={compareDataCycleId}
    />
  );
}

export default Participation;
