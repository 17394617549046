import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { SmallSpinner } from "components/Spinner";
import API from "services/Api.Service";

function Segmentation({ dataCycleId, selectedQuestion }: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion) {
      const url = API_URLS.GetSegmentationResults;

      API.post(url, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          demographics.segmentationQuestion = response?.data?.chartTitle;
          demographics.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographics(demographics);
          setDemographicsLoaded(true);
        })
        .finally(() => {});
    }
  }, [dataCycleId, selectedQuestion]);

  return demographicsLoaded ? (
    <Charts.PieChart config={demographics} />
  ) : (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  );
}

export default Segmentation;
