import { useEffect, useState } from "react";
import { colours } from "components/Charts/Colours";
import { SmallSpinner } from "components/Spinner";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

function CompareSegmentationKey({
  dataCycleId,
  selectedQuestion,
  compareDataCycleId,
}: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion && compareDataCycleId) {
      const url = API_URLS.GetSegmentationComparison;

      API.post(url, {
        dataCycleIds: [dataCycleId, compareDataCycleId],
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          setDemographics(response?.data);
        })
        .finally(() => {
          setDemographicsLoaded(true);
        });
    }
  }, [dataCycleId, selectedQuestion, compareDataCycleId]);

  return (
    <div>
      {demographicsLoaded ? (
        <div className="segmentationKeyContainer">
          {demographics.map((item: any) => {
            if (item.codedResponses !== null) {
              return (
                <div className="segmentationKeyContainer">
                  <p>{item.dataCycleName}</p>
                  {item.codedResponses.map((responses: any, index: any) => {
                    return (
                      <div className="segmentationKeyLine">
                        <div
                          className="segmentationKeyColour"
                          style={{ backgroundColor: colours[index] }}
                        />
                        <div className="segmentationKeyText">{`${responses.keypadDesription}: ${responses.participantsCount} (${responses.participantsPercentage}%)`}</div>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div style={{ width: "150px", height: "150px" }}>
          <SmallSpinner />
        </div>
      )}
    </div>
  );
}

export default CompareSegmentationKey;
